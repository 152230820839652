import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/acrylic.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  schemeBtn,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import dataJson from "../data/substrates.json";

import { StoreContext } from "../context/store-context";

function Arcylicsubstrates() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "substrates/acrylic" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [diameter, setDiameter] = useState(null);
  const [thickness, setThickness] = useState([]);
  const [color, setColor] = useState(null);
  const [form, setForm] = useState(null);

  const [dateCost, setDateCost] = useState([]);

  const [engravingText, setEngravingText] = useState("");

  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Данные для подложек из фанеры
  const acrylicData = dataJson.acrylic;
  // Форма подложки
  const acrylicForm = acrylicData.form;
  // Цвета подложки
  const acrylicColors = acrylicData.colors;
  // Цена для белого и черного
  const acrylicWhiteAndBlack = acrylicData.whiteblack;
  // Цена для прозрачного
  const acrylicTransparent = acrylicData.transparent;

  // Обновить состояние для диаметра
  const handleDiameter = (event, newAlignment) => {
    setDiameter(newAlignment);
    setThickness([]);
  };

  // Обновить состояние для толщины
  const handleThickness = (event, newAlignment) => {
    const innerText = Number(event.target.innerText);
    setThickness([newAlignment, innerText]);
  };

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для формы
  const handleForm = (event, newAlignment) => {
    setForm(newAlignment);
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Обновить состояние для цвета
  const handleColor = (event, newAlignment) => {
    if (newAlignment === "Прозрачный") {
      setDateCost(acrylicTransparent);
    } else setDateCost(acrylicWhiteAndBlack);

    setColor(newAlignment);
    setThickness([]);
  };

  // Добавить первый набор подложек
  const addProduts = () => {
    const dataOrder = {
      diameter: diameter,
      thickness: thickness,
      form: form,
      color: color,
      engravingText: engravingText,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: {
        acrylic: {
          bundle: [...store.substrates.acrylic.bundle, dataOrder],
          totalPrice:
            store.substrates.acrylic.totalPrice + dataOrder.totalAmount,
        },
        lhdf: { ...store.substrates.lhdf },
        plywood: { ...store.substrates.plywood },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });

    setForm(null);
    setDiameter(null);
    setThickness([]);
    setColor(0);
    setEngravingText("");

    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.substrates.acrylic.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.substrates.acrylic.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      substrates: {
        acrylic: {
          bundle: [...arrBundle],
          totalPrice: store.substrates.acrylic.totalPrice - deductibleAmount,
        },
        lhdf: { ...store.substrates.lhdf },
        plywood: { ...store.substrates.plywood },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let t = thickness[0] || 0;
    let q = quantity || 0;
    let e = engravingText ? q * 30 : 0;

    let currentAmount = t * q + e;

    if (currentAmount >= 10000 && currentAmount < 25000) {
      setTotalAmount(Math.ceil(t * q + e - (t * q + e) * 0.05));
    } else if (quantity >= 25) {
      setTotalAmount(Math.ceil(t * q + e - (t * q + e) * 0.1));
    } else {
      setTotalAmount(Math.ceil(t * q + e));
    }
  }, [quantity, thickness, engravingText, totalAmount]);

  return (
    <main className="mainpage">
      <MetaTag title="Подложки из акрила | Wowmilota" />
      <motion.div
        layoutId="acrylicContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография подложки из акрила"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Подложки из акрила</h2>
                </div>
                <p>
                  Самые топовые современные подложки.
                  <br />
                  Эти подложки прочные и не ломаются, им можно доверить самое
                  ценное;
                  <br />
                  Не скользят. Крем на торте сцепляется с подложкой мертвой
                  схваткой;
                  <br />
                  Подложки изготовлены из экологичного материала, допустимого в
                  пищевом производстве;
                  <br />
                  Выглядят стильно и современно;
                  <br />
                  Возможен выбор цвета (черный, белый, прозрачный);
                  <br />
                  Идеальны для муссовых десертов. Подчеркнет глянец на торте и с
                  акцентирует внимание на декоре
                  <br />
                  *Подходят для многоразового применения, поэтому идеально
                  подходят для кондитерских студий.
                  <br />
                  <br />
                </p>
                <p>
                  При заказе от 10 тысяч рублей скидка 5%
                  <br />
                  При заказе от 25 тысяч рублей скидка 10%
                  <br />
                  Скидка применяется автоматически при формировании заказа
                  <br />
                  * Скидка действует только на данный вид товара.
                  <br />
                  <br />
                </p>
                <p>
                  <span>
                    Если вам необходимы другие параметры, вы можете оставить
                    свои пожелания при отправке заявки в поле комментарий к
                    заказу
                  </span>
                </p>
                <p>
                  Схема сборки многоярусного торта{" "}
                  <a
                    href="/pdf/acrylic_scheme.pdf"
                    target="_blank"
                    className={schemeBtn}
                  >
                    открыть
                  </a>
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: diameter
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Диаметр (см)</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={diameter}
                    exclusive
                    onChange={handleDiameter}
                    sx={toggleContainerSx}
                  >
                    {[...Array(29).keys()].map((index) => {
                      const size = index + 12;
                      return (
                        <ToggleButton
                          value={size}
                          key={`diameter${size}`}
                          sx={toggleButtonSx}
                        >
                          {size}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: color
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Цвет</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={color}
                    exclusive
                    onChange={handleColor}
                    sx={toggleContainerSx}
                    key={`colorToggle`}
                  >
                    {acrylicColors.map((item) => {
                      return (
                        <ToggleButton
                          value={item}
                          key={`color${item}`}
                          sx={toggleButtonSx}
                        >
                          {item}
                        </ToggleButton>
                      );
                    })}
                    ;
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: form
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Форма</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={form}
                    exclusive
                    onChange={handleForm}
                    sx={toggleContainerSx}
                  >
                    {acrylicForm.map((item) => {
                      return (
                        <ToggleButton
                          value={item}
                          key={`form${item}`}
                          sx={toggleButtonSx}
                        >
                          {item}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      thickness[0] > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Толщина (мм)</h3>
                  {diameter && color ? (
                    dateCost
                      .filter((item) => {
                        if (item.diameter === diameter) {
                          return item;
                        } else {
                          return null;
                        }
                      })
                      .map((item) => {
                        const { three, five } = item;

                        return (
                          <ToggleButtonGroup
                            size="medium"
                            value={thickness}
                            exclusive
                            onChange={handleThickness}
                            sx={toggleContainerSx}
                            key={`thicknessToggle`}
                          >
                            {three && (
                              <ToggleButton value={three} sx={toggleButtonSx}>
                                3
                              </ToggleButton>
                            )}
                            {five && (
                              <ToggleButton value={five} sx={toggleButtonSx}>
                                5
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        );
                      })
                  ) : (
                    <p>Сначала выберите диаметр и цвет</p>
                  )}
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="acrylicQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Дополнительные параметры</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: engravingText
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Гравировка</h3>
                  <div className={toggle}>
                    <TextField
                      fullWidth
                      id="acrylicEngravingText"
                      label="Кратко опишите"
                      variant="standard"
                      value={engravingText}
                      onChange={handleEngraving}
                      sx={textFieldSx}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.substrates.acrylic.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.substrates.acrylic.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>
                          {item.diameter} см / {item.thickness[1]} мм
                        </p>
                        <p>{item.form}</p>
                        <p>{item.color}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={priceInfo}>
            <div className={mobileProducts}>
              <button onClick={() => setMobileList(!mobileList)}>
                <MdShoppingBasket color="white" />
                <p className={nubmer}>
                  {store?.substrates.acrylic.bundle.length}
                </p>
              </button>
              {mobileList && (
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  grabCursor={true}
                  className={productContainer}
                >
                  {store?.substrates.acrylic.bundle.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={`order_${index}`}
                        className={productItem}
                      >
                        <button
                          className="delProduct"
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>
                          {item.diameter} см / {item.thickness[1]} мм
                        </p>
                        <p>{item.form}</p>
                        <p>{item.color}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
            {totalAmount > 800 && color && form && thickness[0] ? (
              <button className={costBundle} onClick={addProduts}>
                {totalAmount > 0
                  ? `Добавить / ${totalAmount} Р`
                  : "Сформируйте набор"}
              </button>
            ) : (
              <p className={costBundle}>
                {totalAmount > 0 && color && form && thickness[0]
                  ? `${totalAmount} руб. (Заказ от 800 руб.)`
                  : "Сформируйте набор (от 800 руб.)"}
              </p>
            )}
            <Link to="/" className={mobCloseBtn}>
              <MdOutlineClose color="white" />
            </Link>
            <Link to="/" className={closeBtn}>
              {store?.substrates.acrylic.bundle.length > 0
                ? `Сохранить и закрыть`
                : `закрыть`}
            </Link>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Arcylicsubstrates;
